import axios from 'axios';

import { getCookie } from '../helpers';

const onboardUser = (props = {}) => {
    const {
        name,
        icon,
        attachments,
    } = props;

    if (process.env.NODE_ENV !== 'development') {
        axios.post('https://www.advelit.com/.netlify/functions/onboarding', {
            attachments: (attachments || []).map(attachment => ({
                ...(attachment || {}),
                footer: [
                    [
                        getCookie('utm_source'),
                        getCookie('utm_campaign'),
                    ].filter(Boolean).join(' - '),
                    attachment?.footer,
                ].filter(Boolean).join(', '),
            })),
            username: name,
            icon_emoji: icon,
        }, {
            withCredentials: true,
        }).catch(() => {
        });
    }
};

export default onboardUser;
